<template>
  <div v-if="can_show">

    <!-- obj_dados_empresa : {{ obj_dados_empresa }} -->
    <!-- {{ dados_servicos }} -->  
    <!-- {{ dados_parcelas }} -->
    <!-- {{dados_cadastro}} -->
    <!-- {{ dados_impressao }} -->

    <!-- {{get.printConteudo}} -->


    <v-dialog
      v-model="dialog_relatorios"
      max-width="799px"
      scrollable
    >
  <v-card
    class="mx-auto"
  >
    <v-card-title class=" grey lighten-5">
      <h5>Período</h5>

      <v-spacer></v-spacer>
      
      <v-icon
        color="red"
        @click="dialog_relatorios=!dialog_relatorios"
      >
        mdi-close
      </v-icon>

    </v-card-title>

    <v-card-text>
      <v-container class="mx-0 px-0 mt-2">
        <v-row>
          
          <v-col
            cols="12"
            md="3"
            class="ml-2"
          >      
            <v-text-field
              dense
              v-model="modelo_di"
              label="DI"
              hide-details
              type="date"
              
            ></v-text-field>
          </v-col> 
          
          <v-col
            cols="12"
            md="3"
            class="ml-2"
          >      
            <v-text-field
              dense
              v-model="modelo_df"
              label="DF"
              hide-details
              type="date"
              
            ></v-text-field>
          </v-col> 


          <v-col
            cols="12"
            md="2"
            class="ml-2"
          >      
            <v-text-field
              dense
              v-model="modelo_hi"
              label="HI"
              hide-details
              type="time"
              
            ></v-text-field>
          </v-col> 

          <v-col
            cols="12"
            md="2"
            class="ml-2"
          >      
            <v-text-field
              dense
              v-model="modelo_hf"
              label="HF"
              hide-details
              type="time"
              
            ></v-text-field>
          </v-col> 

          <v-col
            cols="12"
            md="1"
            class="ml-2"
          >      
          <v-btn color="primary"
           @click="replace_coringas(dados_impressao.MODELO_IMPRESSAO), dialog_relatorios=false"
          >
            ok
          </v-btn>
          </v-col> 
          

          
          <v-spacer></v-spacer>


        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
    </v-dialog>



    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      :color="snack_color"
      class="no-print"
    >
      <center>
        {{ snack_text }}
      </center>
    </v-snackbar>


    <v-row>


      <!-- coluna esquerda -->
      <!-- <v-col cols="12" md="4" class="grey lighten-5 pa-0 ml-5 no-print"> -->
        <v-col cols="12" md="4" class="" v-if="(!dialog_pre_assinar)">

        <v-container class="no-print" v-if="(!imprimindo)">
          <v-card
            class="mx-2 mt-0 pa-1 dsi_vcard pa-2"
            elevation="8"
          >
            <!-- offset-md="2" deixa um espaço no grid-->

              <v-row class="">

              <v-col class="" cols="3">
                <v-switch
                  v-model="mostrar_logo"
                  label="Logo"
                ></v-switch>
              </v-col>

              <v-col class="" cols="3">
                <v-text-field
                  v-model="(margem_superior)"
                  label="Margem Superior"
                  type="number"
                  hint="1cm"
                ></v-text-field>
              </v-col>

              <v-col class="" cols="3">
                <v-text-field
                  v-model="largura_pagina"
                  label="Largura da página"
                  type="number"
                  hint="A4 = 21cm"
                ></v-text-field>
              </v-col>

              </v-row>
          </v-card>


          <!-- <v-card
            min-height="100"
            class="mx-2 mt-2 pa-1 dsi_vcard pa-2"
            elevation="8"
          >

          </v-card> -->
              <v-card
                class="mx-2 mt-2 pa-1 dsi_vcard pa-2"
              >
              <!-- v-if="editor_atestado" -->
                <v-tabs
                    v-model="tab"
                    background-color="#FAFAFA"
                    show-arrows    
                    height="33"
                    color="" 
                >
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab 
                      href="#editor"
                      v-if="editor_atestado"  
                    >
                        <v-icon
                          size="18"
                        >
                          mdi-text-box-outline 
                        </v-icon>

                        <span>
                          &nbsp Editor
                        </span>
                    </v-tab>
                    
                    <v-tab 
                      href="#servico"
                      v-if="editor_atestado==false"
                    >
                        <v-icon
                          size="18"
                        >
                          mdi-format-list-bulleted-square 
                        </v-icon>

                        <span class="ml-1">
                          {{get.TIPO}}
                        </span>
                    </v-tab>

                </v-tabs>


                <v-tabs-items 
                  v-model="tab"
                  touchless
                >
                  <v-tab-item :key="1" value="editor" class="">
                     <v-card class="">
                       <v-card-subtitle>
                          <h2>
                          {{ get.DESCRICAO }}  
                          </h2>   
                       </v-card-subtitle>

                      <v-card-text>

                        <h3 class="blue--text">Empresa:</h3>
                        
                        <v-chip-group
                         column
                        >
                          <v-chip
                              v-for="(item, i) in tags_empresa"
                              :key="i"
                              small
                              @click="insere_tag(item)"
                          >
                            {{ item }}
                          </v-chip>
                        </v-chip-group>

                        <h3 class="blue--text">Cadastro:</h3>
                        <v-chip-group
                         column
                        >
                          <v-chip
                              v-for="(item, i) in tags_alunos"
                              :key="i"
                              small
                              @click="insere_tag(item)"
                          >
                            {{ item}}
                          </v-chip>
                        </v-chip-group>

                        <h3 class="blue--text">Outros:</h3>
                        <v-chip-group
                         column
                        >
                          <v-chip
                              v-for="(item, i) in tags_outros"
                              :key="i"
                              small
                              @click="insere_tag(item)"
                          >
                            {{ item}}
                          </v-chip>
                        </v-chip-group>

                      </v-card-text>

                       <v-card-actions>
                       <v-spacer></v-spacer>

                       <v-btn
                            color="error"
                            @click="close"
                            small
                            class="mb-1 mr-10"
                          >
                            Cancelar 
                          </v-btn>

                          
                          <v-btn
                            color="success"
                            @click="replace_texto"
                            small
                            class="mb-1"
                          >
                            Replace 
                          </v-btn>

                          <v-btn
                            color="primary"
                            @click="atualizar_edicao"
                            small
                            class="mb-1"
                          >
                            Salvar
                          </v-btn>
                        
                       </v-card-actions>

                     </v-card>
                  </v-tab-item>

                </v-tabs-items>

                <v-tabs-items 
                  v-model="tab"
                  touchless
                >
                  <v-tab-item :key="2" value="servico" class="">
                     <v-card class="grey lighten-4">
                       <v-card-subtitle>

                          <h2 class="blue--text">  {{ dados_cadastro.NOME }} </h2>

                          <h3 class=" mt-2 ml-2">  {{ get.DESCRICAO }} </h3>
                          
                       </v-card-subtitle>

                       <v-card-actions class="">
                       <v-spacer></v-spacer>

                      <v-checkbox
                        v-if="(get.TIPO=='CONTRATO')"
                        v-model="servico_ja_assinou"
                        dense
                        inset
                        label="ASSINADO"
                        color="success"
                        @click.native.capture="pergunta_contrato($event)"
                        @change="assinar_contrato(servico_ja_assinou)" 
                      ></v-checkbox>
                        
                       </v-card-actions>

                     </v-card>
                  </v-tab-item>

                </v-tabs-items>

              </v-card>


              <div  v-if="(!editor_atestado)&&(get.TIPO=='IMPRESSÃO')">
              <v-card class="mx-2 mt-2 py-2"
              >
                <v-container>
                <v-row dense>

                  <v-btn
                   x-small
                   color="primary"
                   @click="dialog_relatorios=true"
                  >
                    definir período
                  </v-btn>


                </v-row>
              </v-container>
              </v-card>

              <v-card
                max-height="400"
                class="mx-2 mt-2 pa-1 dsi_vcard pa-2"
                elevation="8"
                v-if="(!editor_atestado)"
              >

              <h3 class="red--text">
                Selecione um modelo abaixo:
              </h3>
                <v-data-table
                  :headers="headers_impressao"
                  :items="items_impressao"
                  :items-per-page="500"
                  :search="search"
                  class="elevation-1"
                  dense
                  hide-default-footer
                >
                
                <template v-slot:item.DESCRICAO="{ item }">
                  <v-chip
                  small
                  color="primary"
                  @click="escolhe_impressao(item)"
                  >
                  {{ item.DESCRICAO }}
                  </v-chip>

                  <!-- <span
                  color="primary"
                  @click="escolhe_impressao(item)"
                  >
                  <a>
                    {{ item.DESCRICAO }}
                  </a>
                  </span> -->
                </template>         
                
                
                </v-data-table>


              </v-card>
            </div>

        </v-container>
      </v-col>

      
      <!-- coluna direita -->
      <v-col cols="12" md="8" class="" v-if="(!dialog_pre_assinar)">
      <!-- <v-col cols="12" md="7" class="pt-2 grey lighten-5"> -->

        <v-row no-print v-if="(!imprimindo)">
            <v-col>
              <v-card class="mx-auto pa-1 no-print mt-2 mr-1" v-if="2>1">
                
                <v-toolbar dense>

                  <template v-if="$vuetify.breakpoint.mdAndUp">

                    <!-- <v-spacer></v-spacer> -->

                    <v-btn-toggle
                      v-model="toggle_multiple"
                      color="primary"
                      dense
                      group
                      multiple
                    >
                      <v-btn
                        :value="1"
                        text
                        @click="editor.chain().focus().toggleBold().run()"
                      >
                        <v-icon>mdi-format-bold</v-icon>
                      </v-btn>


                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <!-- @click="editor.chain().focus().setColor('#F98181').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#F98181' })}" -->
                          <v-btn v-bind="attrs" v-on="on" :value="2" v-if="2>1">
                            <v-row align="center" class="flex-column" justify="center">
                              <v-icon class="cols 12"> mdi-format-size  </v-icon>

                              <v-sheet
                                tile
                                style="margin-top: -1px"
                                height="4"
                                width="26"
                                color="blue"
                              ></v-sheet>

                            </v-row>
                          </v-btn>
                        </template>
                        <v-list>
                          <!-- <v-list-item @click="set_font_size('90')">
                            <v-list-item-title> </v-list-item-title>
                            <span>90%</span>
                          </v-list-item> -->
                          
                          <v-list-item @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" >
                            <v-list-item-title> </v-list-item-title>
                            <span>24²</span>
                          </v-list-item>
                          
                          <v-list-item @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" >
                            <v-list-item-title> </v-list-item-title>
                            <span>18</span>
                          </v-list-item>
                          
                          <v-list-item @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" >
                            <v-list-item-title> </v-list-item-title>
                            <span>16</span>
                          </v-list-item>
                          
                          <v-list-item @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" >
                            <v-list-item-title> </v-list-item-title>
                            <span>12</span>
                          </v-list-item>
                          
                          <v-list-item @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" >
                            <v-list-item-title> </v-list-item-title>
                            <span>10</span>
                          </v-list-item>

    
                        </v-list>
                      </v-menu>

                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <!-- @click="editor.chain().focus().setColor('#F98181').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#F98181' })}" -->
                          <v-btn v-bind="attrs" v-on="on" :value="3">
                            <v-row align="center" class="flex-column" justify="center">
                              <v-icon class="cols 12"> mdi-format-color-text </v-icon>

                              <v-sheet
                                tile
                                style="margin-top: -4px"
                                height="4"
                                width="26"
                                :color="corTexto"
                              ></v-sheet>
                            </v-row>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="setCorTexto('black')">
                            <v-list-item-title> </v-list-item-title>
                            <v-icon color="black"> mdi-checkbox-blank </v-icon>
                          </v-list-item>

                          <v-list-item @click="setCorTexto('red')">
                            <v-list-item-title></v-list-item-title>
                            <v-icon color="red"> mdi-checkbox-blank </v-icon>
                          </v-list-item>

                          <v-list-item @click="setCorTexto('blue')">
                            <v-list-item-title></v-list-item-title>
                            <v-icon color="blue"> mdi-checkbox-blank </v-icon>
                          </v-list-item>

                          <v-list-item @click="setCorTexto('green')">
                            <v-list-item-title></v-list-item-title>
                            <v-icon color="green"> mdi-checkbox-blank </v-icon>
                          </v-list-item>
                        </v-list>
                      </v-menu>

                    </v-btn-toggle>

                    <!-- <div class="mx-1"></div> -->
                    <v-divider class="mx-1" vertical inset></v-divider>

                    <v-btn-toggle
                      v-model="toggle_exclusive"
                      color="primary"
                      dense
                      group
                    >

                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <!-- @click="editor.chain().focus().setColor('#F98181').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#F98181' })}" -->
                          <v-btn v-bind="attrs" v-on="on" :value="2" v-if="2>1">
                            <v-row align="center" class="flex-column" justify="center">
                              <v-icon>mdi-format-align-justify</v-icon>

                              <v-sheet
                                tile
                                style="margin-top: -1px"
                                height="4"
                                width="26"
                                color="blue"
                              ></v-sheet>
                              
                            </v-row>
                          </v-btn>
                        </template>

                          <v-list>
                            
                            <v-list-item @click="editor.chain().focus().setTextAlign('left').run()" >
                              <v-icon >mdi-format-align-left</v-icon>
                            </v-list-item>
                            
                            <v-list-item @click="editor.chain().focus().setTextAlign('center').run()" >
                              <v-icon >mdi-format-align-center</v-icon>
                            </v-list-item>
                            
                            <v-list-item @click="editor.chain().focus().setTextAlign('right').run()" >
                              <v-icon >mdi-format-align-right</v-icon>
                            </v-list-item>
                            
                            <v-list-item @click="editor.chain().focus().setTextAlign('justify').run()" >
                              <v-icon >mdi-format-align-justify</v-icon>
                            </v-list-item>
                            

      
                          </v-list>
                      </v-menu>


                    </v-btn-toggle>

                    <v-divider class="mx-1" vertical inset></v-divider>

                    <v-btn-toggle
                      v-model="toggle_exclusive"
                      color="primary"
                      dense
                      group
                    >

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="editor.chain().focus().undo().run()"
                            v-bind="attrs"
                            v-on="on"
                            :value="5"
                          >
                            <v-icon>mdi-undo</v-icon>
                          </v-btn>
                        </template>
                        <span>Desfazer</span>
                      </v-tooltip>


                      <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="editor.chain().focus().redo().run()"
                          v-bind="attrs"
                          v-on="on"
                          v-if="0>1"
                        >
                          <v-icon>mdi-redo</v-icon>
                        </v-btn>
                      </template>
                      <span>Refazer</span>
                    </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="update_windows" v-bind="attrs" v-on="on" :value="6">
                            <v-icon>mdi-refresh </v-icon>
                          </v-btn>
                        </template>
                        <span>Atualizar</span>
                      </v-tooltip>

                    </v-btn-toggle>

                    <v-divider class="mx-1" vertical inset></v-divider>


                    <v-btn  
                    @click="teste_here"
                    color="primary text--white"
                    v-if="0>1"
                    >
                      <v-icon color="white"> mdi-printer </v-icon>
                    </v-btn>

                    </template>

                    <v-spacer></v-spacer>

                    



                          <span v-if="(get.TIPO=='Termos')">
                          <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <!-- <v-btn  
                                @click="imprimir"
                                color="success" 
                                > -->
                                <v-icon color="primary" x-large v-bind="attrs" v-on="on" class="hand mr-3" @click="ver_documento()"> 
                                    <!-- mdi-text-box  -->
                                    <!-- mdi-text-box-check-outline -->
                                    <!-- mdi-signature-freehand -->
                                    mdi-draw-pen
                                  </v-icon>
                                <!-- </v-btn> -->
                              </template>
                              <span>Assinar e Salvar Termo</span>
                            </v-tooltip>
                          </span>


                      


                          <span v-if="(PrintPode)">
                          <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <!-- <v-btn  
                            @click="imprimir"
                            color="success" 
                            > -->
                            <v-icon color="success" x-large v-bind="attrs" v-on="on" class="hand mr-3" @click="salvar_em_documentos()"> 
                                <!-- mdi-text-box  -->
                                <!-- mdi-text-box-check-outline -->
                                mdi-text-box-plus
                              </v-icon>
                            <!-- </v-btn> -->
                          </template>
                          <span>Salvar em Atendimento, Registros</span>
                          </v-tooltip>
                          </span>
                      


                          <v-btn  
                          @click="imprimir"
                          color="primary text--white" class="ml-5"
                          >
                            <v-icon color="white"> mdi-printer </v-icon>
                          </v-btn>

                     <v-divider class="mx-2" vertical inset></v-divider>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="close" v-bind="attrs" v-on="on">
                            <v-icon color="red"> mdi-window-close </v-icon>
                          </v-btn>
                        </template>
                        <span>Fechar</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="verificar_enviadosHERE()" v-bind="attrs" v-on="on" v-if="0>1">
                            testar
                          </v-btn>
                        </template>
                        <span>Testar</span>
                      </v-tooltip>



                  
                </v-toolbar>
              </v-card>

            </v-col>
          </v-row>


        <v-card class="no-print" v-if="(0>1)">

          <v-row no-gutters  class="no-print mt-1 pa-2 pb-1 white lighten-5">

              <v-btn-toggle
                tile
                dense
              >
                <v-btn
                @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" 
                >
                  h1
                </v-btn>

                <v-btn
                @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" 
                >
                  h2
                </v-btn>

                <v-btn
                @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" 
                >
                  h3
                </v-btn>

                <v-btn
                @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" 
                >
                  h4
                </v-btn>
              </v-btn-toggle>

              <v-divider  class="mx-2" vertical ></v-divider>

              <v-btn-toggle v-model="formatting" dense>
                <v-btn
                  @click="editor.chain().focus().toggleItalic().run()"
                >
                  <v-icon>mdi-format-italic</v-icon>
                </v-btn>

                <v-btn
                  @click="editor.chain().focus().toggleBold().run()"
                >
                  <v-icon>mdi-format-bold</v-icon>
                </v-btn>

                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <!-- @click="editor.chain().focus().setColor('#F98181').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#F98181' })}" -->
                    <v-btn v-bind="attrs" v-on="on">
                      <v-row align="center" class="flex-column" justify="center">
                        <v-icon class="cols 12"> mdi-format-color-text </v-icon>

                        <v-sheet
                          tile
                          style="margin-top: -4px"
                          height="4"
                          width="26"
                          :color="corTexto"
                        ></v-sheet>
                      </v-row>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="setCorTexto('black')">
                      <v-list-item-title> </v-list-item-title>
                      <v-icon color="black"> mdi-checkbox-blank </v-icon>
                    </v-list-item>

                    <v-list-item @click="setCorTexto('red')">
                      <v-list-item-title></v-list-item-title>
                      <v-icon color="red"> mdi-checkbox-blank </v-icon>
                    </v-list-item>

                    <v-list-item @click="setCorTexto('blue')">
                      <v-list-item-title></v-list-item-title>
                      <v-icon color="blue"> mdi-checkbox-blank </v-icon>
                    </v-list-item>

                    <v-list-item @click="setCorTexto('green')">
                      <v-list-item-title></v-list-item-title>
                      <v-icon color="green"> mdi-checkbox-blank </v-icon>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-btn-toggle>

              <v-divider  class="mx-2" vertical ></v-divider>

              <v-btn-toggle v-model="alignment" dense>
                <v-btn
                  @click="editor.chain().focus().setTextAlign('left').run()"
                >
                  <v-icon>mdi-format-align-left</v-icon>
                </v-btn>

                <v-btn
                  @click="editor.chain().focus().setTextAlign('center').run()"
                >
                  <v-icon>mdi-format-align-center</v-icon>
                </v-btn>

                <v-btn
                  @click="editor.chain().focus().setTextAlign('right').run()"
                >
                  <v-icon>mdi-format-align-right</v-icon>
                </v-btn>

                <v-btn
                  @click="editor.chain().focus().setTextAlign('justify').run()"
                >
                  <v-icon>mdi-format-align-justify</v-icon>
                </v-btn>
              </v-btn-toggle>

              <v-divider  class="mx-2" vertical ></v-divider>

              <v-btn-toggle dense>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="editor.chain().focus().undo().run()"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-undo</v-icon>
                    </v-btn>
                  </template>
                  <span>Desfazer</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="editor.chain().focus().redo().run()"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-redo</v-icon>
                    </v-btn>
                  </template>
                  <span>Refazer</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="update_windows" v-bind="attrs" v-on="on">
                      <v-icon>mdi-refresh </v-icon>
                    </v-btn>
                  </template>
                  <span>Atualizar</span>
                </v-tooltip>
              </v-btn-toggle>

              <v-divider class="mx-2" vertical ></v-divider>

              <v-btn-toggle dense>
                <v-btn
                  @click="imprimir"
                  color="primary text--white"
                >
                  <v-icon color="white"> mdi-printer </v-icon>
                </v-btn>
              </v-btn-toggle>

              <v-divider class="mx-2" vertical ></v-divider>
              
              <v-btn-toggle dense>
                <!-- <v-tooltip bottom> -->
                  <!-- <template v-slot:activator="{ on, attrs }"> -->
                    <v-btn @click="close" color="red" dark>
                      <v-icon color="white"> mdi-window-close </v-icon>
                    </v-btn>
                  <!-- </template>
                  <span>Fechar</span>
                </v-tooltip> -->
              </v-btn-toggle>

          </v-row>
        </v-card>

         <!-- <br class="no-print"/> -->

         <v-card 
              :class="[(imprimindo) ? '' : 'overflow-y-auto' , 'mt-2 mx-auto pa-2 px-5']"
              :elevation="elevacao"  
              id="content-to-print"
              :height="(imprimindo) ?'' :  retorna_altura(90)"
          >
          <!-- :height="retorna_altura(90)" isso daqui dava erro, gerava muitas folhas na impressao -->
          
          <v-row>
            <v-col>

              <div ref="contentToPrint" class="">

         
          <v-card
            :max-width="(parseFloat(largura_pagina) - parseFloat(largura_dif)) + 'cm'"
            class="mx-auto pa-1 dsi_vcard"
            :elevation="elevacao"
          >
            <div>


              <!-- PAINEL LOGO CABEÇALHO -->
              <!-- card margem superior -->
              <v-card
                :height="(margem_superior - 1)+'cm'"
                class=""
                :elevation="elevacao"
              >
              <!-- margem_superior -->

              </v-card>

              <v-card
                :height="(mostrar_logo) ?   '2.2cm' : '0.5cm'"
                id="id_cabecalho"
                :elevation="elevacao"
                class=""
              >
                <center v-if="(mostrar_logo)">
                  <img
                    ref="print_logo"
                    :src="urlLogo"
                    style="max-width: auto; height: 79px"
                    class="mt-0"
                  />
                </center>
              </v-card>



            <v-card class="" 
              :disabled="(servico_ja_assinou)&&(!editor_atestado)&&(get.TIPO=='CONTRATO')"
              min-height="300"
              :elevation="elevacao"
            >
            <!-- {{servico_ja_assinou}} -->
              <div v-if="editor">
                <editor-content
                  :editor="editor"
                  @keydown.native.capture="pode($event)"
                  @click.right.native.capture="pode($event)"
                />
                <!-- :style="{ maxHeight: altura_meio + 'cm' }"   -->
                <!-- :style="{ minHeight: altura_meio + 'cm' ,border: 1+'px solid' }" -->
              </div>
            </v-card>


              <v-row
              v-if="TESTE == true"
              >
                DSI
                <v-col cols="12" md="12">
                  <v-textarea
                    background-color="#FAFAFA"
                    v-model="pegaTexto"
                    label="EVOLUÇÃO"
                    hint=""
                    rows="16"
                    :auto-grow="false"
                    row-height="8"
                    textarea-line-height="1.2rem"
                  >
                  </v-textarea>
                </v-col>
              </v-row>


            </div>
          </v-card>


        </div>
                </v-col>
                </v-row>
                </v-card>
         
        
      </v-col>

    </v-row>
  </div>
</template>






<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import Image from '@tiptap/extension-image'

import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
import { define_data_hora } from '../assets/js/DSi_basico'
import { logado } from '../assets/js/DSi_rotinas'


export default {
  components: {
    EditorContent,
  },

  data() {
    return {

      obj_dados_empresa: null,

      dialog_pre_assinar: false,
      toggle_multiple: [],
      toggle_exclusive: 0,
      PrintPode: true,
      imprimindo: false,

      TESTE : false,
      editor_atestado: false,  

      modelo_hi: '',
      modelo_hf: '',

      can_show: false,
      isMobile: false,

      dialog_relatorios: false,
      modelo_di:'',
      modelo_df:'',
      modelo_hi:'',
      modelo_hi:'',
      
      dados_cadastro:[],
      dados_parcelas:[],
      dados_servicos:[],
      dados_impressao:[],

      search:'',
      items_impressao:[],
      headers_impressao:[
        { text: 'MODELOS', value: 'DESCRICAO' },
        // { text: 'M_LOGO', value: 'M_LOGO', tag:'v-text', type:'text' },
        // { text: 'M_LARGURA_PAGINA', value: 'M_LARGURA_PAGINA', tag:'v-text', type:'text'  },
        // { text: 'MODELOS', value: 'MODELO_IMPRESSAO' },
        // { text: 'CODIGO', value: 'CODIGO' },
      ],

      tab: 'editor',

      data_hora:'',

      servico_ja_assinou: false,

      getImpressos:[],

      nome_modelo:'',

      elevacao: 4,
      editor: null,
      corTexto: 'blue',
      formatting: null,
      alignment: null,

      urlLogo:'',

      get:{
        TIPO:'', 
        CODIGO : 0,
        TABELA:'',
        CAMPO:'',
        DESCRICAO:'',
        NOME_CADASTRO:'',
        printConteudo:'',
        printRodape:'',
      },

      pegaTexto:'',
      pegaRodape:'',
      tamanho_pagina : '10cm', 
      altura_cabecalho:'2.1', //3cm
      altura_meio: '16', //16cm
      altura_rodape:'5.5',//5.5cm
      largura_pagina:'21',//5.5cm
      // largura_pagina: 730,
      largura_dif: '2.5',
      margem_superior: '1',

      mostrar_logo: true,

      snackbar: false,
      snack_text: 'My timeout is set to 2000.',
      snack_color: "success",

      tags_alunos:[],
      tags_empresa:[],
      tags_outros:[],

    }
  },

  created(){
      this.onCreated()
  },

  mounted() {

    this.urlLogo    =  sessionStorage['emp_url_logo']
    this.pegaTexto  = this.get.printConteudo
    this.pegaRodape = this.get.printRodape

    this.editor = new Editor({
      extensions: [
        StarterKit,
        Document,
        Text,
        Image,
        Paragraph,
        TextStyle,
        Color,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
      ],


      content : this.pegaTexto,
    })
  },

  beforeUnmount() {
    this.editor.destroy()
  },


  watch:{

  },

  methods:{


    async salvar_em_documentos(assinatura){

      if (!assinatura){
          if (!confirm("Este Texto será Salvo na guia ATENDIMENTO, REGISTROS. Deseja continuar?")){
            return false
          }
      }

          if (!assinatura){assinatura = ''}

          // let nome = this.nome_modelo.toUpperCase()
          // if (!nome){ nome = 'XX'}


          let sql = `
          INSERT INTO WEB_REGISTROS
          (
            NOME_TEXTO,
            TEXTO_REGISTRO, 
            TIPO,
            CODIGO_CLIENTE , 
            DATA,
            PATH_ASSINATURA
          )
          VALUES 
          (
            '${this.dados_impressao.DESCRICAO}',
            '${this.editor.getHTML()}', 
            'IMPRESSÃO', 
            ${this.dados_cadastro.CODIGO_ALUNO}, 
            '${this.retorna_today()}',
            '${assinatura}'
            
          )`
          

          // MEMORIZA O NOVO MODELO
          localStorage['printModeloAtual_'+ this.tipo_atestado] = this.nome_modelo

          // console.log('sql:', sql);
          if (await this.crud_sql(sql)){
            // this.dialog_pre_assinar = false
            this.dialog_assinar     = false

            setTimeout(function(){
              // alert('Registro Salvo com sucesso!')
              this.dialog_pronto = true
            }.bind(this), 200);
            
          }

    },

    retorna_today(){
      let hoje = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
      // console.log('hoje:'+ hoje);

      let today = hoje.slice(6, 10) +'-'+ hoje.slice(3, 5) +'-'+ hoje.slice(0, 2) ; //formato us yyyy-mm-dd
      return today
    },

    retorna_altura(menos){
        // let r = '100vh'
        // let r = '720'
        // let r = (window.innerHeight - 80 - 60)
        let r = (window.innerHeight - menos)
        let height = window.innerHeight;

        return r
    },

    async onCreated(){

      if (await logado(this)){
          
        await this.showDrawer(false)

        this.atu_data_hora()
        this.abre_lista_impressao()
        this.ler_sessionStorage()
        this.define_tags_key()

        this.editor.commands.insertContent(this.get.printConteudo)
      }
    },

    async showDrawer(value){
       this.$emit('executar', value)//mostrar ou nao paineis
       this.can_show = true
       this.isMobile = window.innerWidth < 600
    },

    getFormatDate(data){
      let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
      return dia 
    },

    atu_data_hora(){
        define_data_hora(this)//passo o this para que la ele defina as variaveis daqui

        this.modelo_di = this.today
        this.modelo_df = this.today

        let h = this.hora
        h =  h.substring(0,3)+'00' //hora e minutos somente
        this.modelo_hi = h
        this.modelo_hf = h
    },

    escolhe_impressao(item){
      
        this.replace_coringas(item.MODELO_IMPRESSAO)

        this.dados_impressao = item

        this.ler_medidas(this.dados_impressao)//pega medias da pagina
        
    },


    replace_coringas(entra){
 
        let texto = ''
        let cadastro = this.dados_cadastro
        let servico  = this.dados_servicos
        let parcelas = this.dados_parcelas
        let fones    = ''

        // console.log(cadastro);
        

        if (entra){
          texto = entra
        }


        // fones = sessionStorage['emp_fone1']+ '  '+sessionStorage['emp_fone2']
        fones = sessionStorage['emp_fone1']+ '⠀⠀'+this.obj_dados_empresa.CELULAR

        //substituindo os campos coringas
        texto = texto.replaceAll('@empresa',        sessionStorage['emp_nome'])
        texto = texto.replaceAll('@razao_social',   sessionStorage['emp_razao'])
        texto = texto.replaceAll('@cnpj',           sessionStorage['emp_cnpj'])
        texto = texto.replaceAll('@endereco_emp',   sessionStorage['emp_endereco'])
        texto = texto.replaceAll('@fones',          fones)
        texto = texto.replaceAll('@cidade',         sessionStorage['emp_cidade'])
        texto = texto.replaceAll('@data',           this.hoje_week )
        texto = texto.replaceAll('@di',             this.getFormatDate(this.modelo_di) )
        texto = texto.replaceAll('@df',             this.getFormatDate(this.modelo_df) )
        texto = texto.replaceAll('@hi',             this.modelo_hi )
        texto = texto.replaceAll('@hf',             this.modelo_hf )


        texto = texto.replaceAll('@ide',             'IDE: '+cadastro.CODIGO_ALUNO)
        texto = texto.replaceAll('@nome',            cadastro.NOME)
        texto = texto.replaceAll('@aluno',           cadastro.NOME)
        texto = texto.replaceAll('@rg',              cadastro.RG )
        texto = texto.replaceAll('@cpf',             cadastro.CPF)
        texto = texto.replaceAll('@nascimento',      cadastro.DATA_NASCIMENTO)
        texto = texto.replaceAll('@processo',        cadastro.PROCESSO)
        texto = texto.replaceAll('@endereco_al',     cadastro.ENDERECO+' '+cadastro.BAIRRO+' CEP:'+cadastro.CEP+' '+cadastro.CIDADE+' - '+cadastro.UF)
        
        texto = texto.replaceAll('@servico',         servico.DESCRICAO)
        texto = texto.replaceAll('@serviço',         servico.DESCRICAO)
        texto = texto.replaceAll('@categoria',       servico.CATEGORIA)
        texto = texto.replaceAll('@valor_servico',   servico.x_double_TOTAL)

        texto = texto.replaceAll('@naturalidade',   cadastro.NATURALIDADE)
        texto = texto.replaceAll('@profissao',      cadastro.PROFISSAO)

        if (texto.includes('@estrutura pagamento')){
        
            let TITULO = ''
            let DESC   = ''

            TITULO = '<br>IDE:'+cadastro.CODIGO_ALUNO +'   -   PROCESSO: '+ servico.PROCESSO + '\n'+
                      'PLANO DE PAGAMENTO:'+ '<br>'

            console.log(parcelas);

            for (var i = 0; i < parcelas.length; i++) {

                // console.log(parcelas);
                if ((i == 0) && (parcelas[i].PAGO == 'S')){

                    let parcelado = Number(DSibasico.strtofloat(servico.double_TOTAL))   - Number(DSibasico.strtofloat(parcelas[i].double_TOTAL))

                    TITULO += 'VALOR TOTAL: '+ servico.x_double_TOTAL + ' | '+
                              'ENTRADA: '+ parcelas[i].x_double_TOTAL + ' | '+
                              'PARCELADO: R$ '+ DSibasico.getFormatCurrency(parcelado)+'<br>'

                }else{
                    // DESC += (i+1) +'ª parcela: '+ this.getFormatDate(parcelas[i].DATA_VENCIMENTO) + '   ' + parcelas[i].x_double_TOTAL + ' = '+parcelas[i].SERVICO_REFERENTE +'<br>'
                    if (parcelas[i].TIPO == 'E'){//somente entradas e nao saidas
                       DESC += i +'ª parcela: '+ this.getFormatDate(parcelas[i].DATA_VENCIMENTO) + '   ' + parcelas[i].x_double_TOTAL + ' = '+parcelas[i].SERVICO_REFERENTE +'<br>'
                    }
                    
                }

            }

            // console.log(TITULO);
            TITULO += DESC

            texto = texto.replaceAll('@estrutura pagamento', TITULO)
        }
                

        this.editor.commands.setContent(texto)

       sessionStorage['printConteudo'] = texto
      //  sessionStorage['printRodape']   = novo_rodape

    },

    async abre_lista_impressao () {
        
      let sql = `select * from tabela_impressao`
      this.crud_abrir_tabela(sql, 'items_impressao')
    },

    insere_tag(tag){
      // alert(tag)
      // this.editor.commands.setContent(tag)
      this.editor.chain().focus().toggleBold().run()

      this.editor.commands.insertContent(tag)

      this.editor.chain().focus().toggleBold().run()

      

      // https://tiptap.dev/api/commands/insert-content
    },

    //uso isso daqui direto na janela onde irei mandar impressao
    replace_texto(){

        let novo_texto = this.editor.getHTML()

        let b = '<strong>'
        let bb = '</strong>'

        //substituindo os campos coringas
        // novo_texto = novo_texto.replace('[empresa]', '@empresa')
        novo_texto = novo_texto.replaceAll('[empresa]', b+'@razao_social'+bb)
        novo_texto = novo_texto.replaceAll('[EMPRESA]', b+'@razao_social'+bb)
        novo_texto = novo_texto.replaceAll('[cnpj]', b+'@cnpj'+bb)
        novo_texto = novo_texto.replaceAll('[CNPJ]', b+'@cnpj'+bb)
        novo_texto = novo_texto.replaceAll('[endereco_emp]', b+'@endereco_emp'+bb)
        novo_texto = novo_texto.replaceAll('[fones]', b+'@fones'+bb)
        novo_texto = novo_texto.replaceAll('[fone_emp]', b+'@fones'+bb)
        novo_texto = novo_texto.replaceAll('[fone_al]', b+'@fones'+bb)
        novo_texto = novo_texto.replaceAll('[cidade]', b+'@cidade'+bb)
        novo_texto = novo_texto.replaceAll('[CIDADE]', b+'@cidade'+bb)
        novo_texto = novo_texto.replaceAll('[data]', b+'@data'+bb)
        
        novo_texto = novo_texto.replaceAll('[naturalidade]'   , b+'@naturalidade'+bb)
        novo_texto = novo_texto.replaceAll('[profissao]'      , b+'@profissao'+bb)

        novo_texto = novo_texto.replaceAll('[ide]', b+'@ide'+bb)
        novo_texto = novo_texto.replaceAll('[categoria]', b+'@categoria'+bb)
        novo_texto = novo_texto.replaceAll('[cat]', b+'@categoria'+bb)
        novo_texto = novo_texto.replaceAll('[nome]', b+'@nome'+bb)
        novo_texto = novo_texto.replaceAll('[NOME]', b+'@nome'+bb)
        novo_texto = novo_texto.replaceAll('[rg]', b+'@rg'+bb)
        novo_texto = novo_texto.replaceAll('[RG]', b+'@rg'+bb)
        novo_texto = novo_texto.replaceAll('[cpf]', b+'@cpf'+bb)
        novo_texto = novo_texto.replaceAll('[CPF]', b+'@cpf'+bb)
        novo_texto = novo_texto.replaceAll('[data_nascimento]', b+'@nascimento'+bb)
        novo_texto = novo_texto.replaceAll('[processo]', b+'@processo'+bb)
        novo_texto = novo_texto.replaceAll('[endereco_al]', b+'@endereco_al'+bb)
        novo_texto = novo_texto.replaceAll('[estrutura pagamento]', b+'@estrutura pagamento'+bb)
        novo_texto = novo_texto.replaceAll('[servico]', b+'@servico'+bb)
        novo_texto = novo_texto.replaceAll('[aluno]', b+'@nome'+bb)
        novo_texto = novo_texto.replaceAll('[valor_carteira]', b+'@valor_servico'+bb)
        novo_texto = novo_texto.replaceAll('[total_extenso]', '')
        novo_texto = novo_texto.replaceAll('[DI]', b+'@di'+bb)
        novo_texto = novo_texto.replaceAll('[DF]', b+'@df'+bb)
        novo_texto = novo_texto.replaceAll('[HI]', b+'@hi'+bb)
        novo_texto = novo_texto.replaceAll('[HF]', b+'@hf'+bb)
      
        novo_texto = novo_texto.replace('[]', '@')
        
        this.editor.commands.setContent(novo_texto)

    },

    define_tags_key(){

      this.tags_empresa = [
        '@empresa',
        '@razao_social',
        '@cnpj',
        '@endereco_emp',
        '@fones',
        '@cidade',
        '@data',
      ] 

      this.tags_alunos = [
        '@ide',
        '@categoria',
        '@nome',
        '@rg',
        '@cpf',
        '@nascimento',
        '@processo',
        '@endereco_al',
        '@estrutura pagamento',
        '@servico',
        '@naturalidade',
        '@profissao',
      ] 

      this.tags_outros = [
        '@di',
        '@df',
        '@hi',
        '@hf',
      ] 
    },

    assinar_contrato(vem){
        // alert(this.servico_ja_assinou)

        let texto =  this.editor.getHTML()//pega o texto

        let sql2 = ''

        let assinou = 'N'
        if (vem==true){
            assinou = 'S'
            sql2 = `CONTRATO = '`+ texto  +`',`
        }


        let sql = 
          `UPDATE servicos SET `+
          sql2+
          `JA_ASSINADO = '`+ assinou  +`'
          ,DATA_HORA_ASSINADO = '` + this.data_hora + `'
          WHERE CODIGO = `+ this.get.CODIGO +`
        `

        // alert(sql)
        this.crud_sql(sql)

        sessionStorage['printConteudo']    = this.pegaTexto
        sessionStorage['refresh']          = 'S' //salvo que pode dar um refresh na tabela_servicos

        sessionStorage['refresh_cadastro'] = 'S'
    },

    pode(event){
      // console.log(this.servico_ja_assinou);
      if (this.editor_atestado == false){
        if (this.servico_ja_assinou==true){
            event.stopPropagation() //para e nao muda o status
            event.preventDefault()
            console.log('nao pode alterar contrato:')
            this.alerta('Contrato já foi assinado, não pode ser alterado!', 'red')
        }
      }
    },

    pergunta_contrato(event){
      
      //  console.log(event);
      //  event.stopPropagation()
      // event.preventDefault()

      let vem = this.servico_ja_assinou

      if (vem !== true){
          if (confirm("Após Assinado, não será possível Alterar o Contrato! Deseja continuar?")){
            //  alert('pode alterar')
          }
          else{
            event.stopPropagation() //para e nao muda o status
          }
      }
      else {
          if (confirm("Definir Contrato como não Assinado?")){
            //  alert('pode alterar')
          }else{
            event.stopPropagation() //para e nao muda o status
          }
      }
    },

    atualizar_edicao(tabela, campo, codigo){ 

      tabela = this.get.TABELA
      campo  = this.get.CAMPO
      codigo = this.get.CODIGO

      this.pegaTexto = this.editor.getHTML()//pega o texto


      let sqlMedidas = ''
      if (tabela=='tabela_impressao'){
         sqlMedidas =  `, M_LOGO = '${(this.mostrar_logo ? 'S':'N')}'`
         sqlMedidas += `, M_LARGURA_PAGINA  = '${this.largura_pagina}'`
         sqlMedidas += `, M_MARGEM_SUPERIOR = '${this.margem_superior}'`
      }

      let sql = `UPDATE ${tabela} SET ${campo} = '${this.pegaTexto}' ${sqlMedidas}  WHERE CODIGO = ${codigo}`

      // console.log(sql);
      this.crud_sql(sql)

      sessionStorage['printConteudo']  = this.pegaTexto
      sessionStorage['refresh']        = 'S' //salvo que pode dar um refresh na tabela_servicos
    },

  

    escolhe_atestado(item){
      this.editor.commands.setContent(item.TEXTO)//definio o texto

      this.pegaTexto = this.editor.getHTML()
      this.getCodigoAtestado = item.CODIGO
      this.nome_modelo = item.NOME
      
    },


    
    setCorTexto(cor){
      
      //isso aqui era chamado direto no botao.. adaptei
      // @click="editor.chain().focus().setColor('red').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: 'red' })}"

      this.corTexto = cor
      this.editor.chain().focus().setColor(cor).run() 
      this.editor.class={ 'is-active': this.editor.isActive('textStyle', { color: cor })}
      
    },


    imprimir(){

      this.elevacao = 0 //remove elevacao
      this.imprimindo = true

      setTimeout(function(){
        window.print()
      }.bind(this), 100);//usar esse .bind(this) para chamar funcoes com o setTimeout

      setTimeout(function(){
        this.elevacao = 4 //volta elevacao
        this.imprimindo = false
      }.bind(this), 1000);//usar esse .bind(this) para chamar funcoes com o setTimeout

      
      // window.print()
    },

    close(){
      window.close()
    },

    salvar_medidas(){

        // sessionStorage['altura_cabecalho']      = this.altura_cabecalho
        sessionStorage['altura_meio']           = this.altura_meio
        sessionStorage['mostrar_logo']          = this.mostrar_logo 

        this.alerta('Salvo','green')
    },

    ler_medidas(item){


        // console.log(item);
        this.mostrar_logo       = (item.M_LOGO=='N' ? false : true)
        this.largura_pagina     = (item.M_LARGURA_PAGINA ? item.M_LARGURA_PAGINA : 21)
        this.margem_superior    = (item.M_MARGEM_SUPERIOR ? item.M_MARGEM_SUPERIOR : 1)


        // if(this.sessionStorage('mostrar_logo') !== undefined){
        //   if (this.sessionStorage('mostrar_logo') == 'true'){
        //     this.mostrar_logo = true
        //   }
        //   else{
        //     this.mostrar_logo = false
        //   }
        // }
    },

    alerta(text,color){
         this.snackbar = true
         this.snack_text= text
         this.snack_color = color

          if ( (text.includes('ERROR:')) || (text.includes('error'))){ 
              this.snack_color = red
          }
    },

    ler_sessionStorage(){
      

      // transformar em objeto novamente
      this.dados_cadastro = (sessionStorage['print:dados_cadastro']) ?   JSON.parse(sessionStorage['print:dados_cadastro']) : {} 
      this.dados_parcelas = (sessionStorage['print:dados_parcelas']) ?   JSON.parse(sessionStorage['print:dados_parcelas']) : {} 
      this.dados_servicos = (sessionStorage['print:dados_servicos']) ?   JSON.parse(sessionStorage['print:dados_servicos']) : {} 

      try { this.obj_dados_empresa = JSON.parse(sessionStorage['obj_dados_empresa']) }
      catch (error) { console.log('erro:', error); }

      //pega dados da impressao.. vindos da tabela, tabela_impressao
      this.dados_impressao = (sessionStorage['print:dados_impressao']) ?   JSON.parse(sessionStorage['print:dados_impressao']) : {} 


      this.$set(this.get, 'TIPO',       sessionStorage['print:get_tipo'])
      this.$set(this.get, 'CODIGO',     sessionStorage['print:get_codigo'])
      this.$set(this.get, 'TABELA',     sessionStorage['print:get_tabela'])
      this.$set(this.get, 'CAMPO',      sessionStorage['print:get_campo'])
      this.$set(this.get, 'DESCRICAO',  sessionStorage['print:get_descricao'])
      this.$set(this.get, 'NOME_ALUNO', sessionStorage['print:get_nome_aluno'])

      this.$set(this.get, 'printConteudo', sessionStorage['printConteudo'])
      this.$set(this.get, 'printRodape',   sessionStorage['printRodape'])


      this.servico_ja_assinou    = this.sessionStorage('servico_ja_assinou')
      this.servico_ja_assinou    = this.servico_ja_assinou == 'S' ?  (true) : (false) 
      this.data_hora             = this.sessionStorage('data_hora')
    

      let a                      = this.sessionStorage('editar_impressao')
      if (a=='S'){
         this.editor_atestado = true 
      }


      this.ler_medidas(this.dados_impressao)//pega medias da pagina
      this.abre_lista_impressao()

    },

     sessionStorage($name){
        if (sessionStorage[$name]) {
           return sessionStorage[$name]
        } 
     },

     update_windows(){
       location.reload();
     },


    async crud_sql (sql) {
        await DSisql.crud_sql(this, sql)
    },


    //ABRE OS DADOS DA TABELA
    async crud_abrir_tabela(sql, items_name){
        DSisql.crud_abrir_tabela(this, sql, items_name)
    }, 


  },
}
</script>

<style lang="scss">
/* Basic editor styles */

.v-application p {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.ProseMirror {
  // min-height: 16cm;
  // background-color: red;

  min-height: 300px;

  // margin-bottom: 300px; 

  overflow: hidden;
  // overflow: scroll;

  border-style: solid;
  border-style: none;

  margin-top: 8px;
  // margin-top: 1rem;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  //  line-height: 0.5cm;
  p {
    line-height: 1.3;
    font-size: 12px;//mudei as margens do  .v-application p DSI
    // padding-top: 0.3cm;
    // margin: 10px;
    // background-color: blue;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  mark {
    background-color: #faf594;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

.only-print {
  display: none !important;
}

@media print {
  .no-print {
    display: none !important;
  }

  .only-print {
    display: block !important;
  }

  .ProseMirror {
    border-style: none;
  }

  .dsi_vcard {
    // max-width: 550px !important;
    // background-color: red !important;
  }

  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */

  .v-toolbar {
    display: none !important;
  }

  .v-navigation-drawer {
    display: none !important;
  }

  @page {
    size: auto;
    margin: 0;
    // margin-top: -2cm;
    margin-top: 1cm; 
    margin-bottom: 1.5cm; 
    // size: 21cm 29.7cm;//A4
    // size: 21cm 21cm;
    // margin: 30mm 45mm 30mm 45mm;
    /* change the margins as you want them to be. */
  }

  @page:first {
     margin-top: 0;           
  }  

  #editor {
    background-color: red;
  }
}

#id_cabecalho {
  // border-style: solid !important;
  border-width: 2px !important;
  border-color: black;
  // background-color: red;
}

#id_rodape {
  // border-style: solid !important;
  border-width: 2px !important;
  border-color: black;
  // background-color: red;
}

.ProseMirror-focused {
  border-style: none !important;
}

.v-btn-toggle {
  margin-top: 1px;
  margin-bottom: 4px;
}

//   .v-tab{
//   // font-size: 9pt  !important; /* usando o sass-variables */
//  }
</style>